import React from 'react';

import Layout from '../components/Layout';

const NotFoundPage = () => (
    <Layout>
        <h1>NOT FOUND</h1>
    </Layout>
);

export default NotFoundPage;
